@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.max-width {
  @apply lg:mx-auto lg:px-24 xl:px-48 px-5;
}
.bg-gradient {
  background-image: linear-gradient(90deg, #4A9FFF, #E84A5F, #A239CA);
  -webkit-background-clip: text;
  background-clip: text;
}
.text-gradient {
  @apply bg-clip-text bg-gradient text-transparent;
}
.section {
  @apply pt-28 xl:pt-28 2xl:pt-44;
}
.text-content {
  @apply dark:text-light-content text-dark-content;
}
.projectImg {
  height: 185px;
}
body {
  overflow-x: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

.typing-cursor {
  display: inline-block;
  margin-left: 1px;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

/* Add this to your CSS file */
.name-char {
  display: inline-block;
  transition: transform 0.3s ease;
  will-change: transform, color;
}

.name-container {
  cursor: pointer;
}


/* Add hover cursor to indicate interactivity */
h1[ref="nameRef"] {
  cursor: pointer;
}

/* Add these styles to your CSS file */

/* Resume link hover effect */
nav ul li span.cursor-pointer {
  position: relative;
}

nav ul li span.cursor-pointer::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-image: linear-gradient(90deg, #4A9FFF, #E84A5F, #A239CA);
  transition: width 0.3s ease;
}

nav ul li span.cursor-pointer:hover::after {
  width: 100%;
}

/* Resume popup animation */
@keyframes fadeIn {
  from { opacity: 0; transform: translate(-50%, 10px); }
  to { opacity: 1; transform: translate(-50%, 0); }
}

/* Add this class to the resume popup div */
.resume-popup {
  animation: fadeIn 0.3s ease forwards;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Embedded PDF styling */
.pdf-preview {
  background-color: white;
  border-radius: 4px;
  pointer-events: none; /* Prevents scrolling and interactions */
}

.resume-popup .h-\[400px\] {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  overflow: hidden;
}


/* Dark mode styling for the container */
.dark .resume-popup .h-\[400px\] {
  background-color: #333;
}

/* Dark mode adjustments */
.dark .pdf-preview {
  filter: invert(0.9) hue-rotate(180deg);
}

/* Make sure the popup is properly positioned */
@media (max-width: 640px) {
  .resume-popup {
    width: 95vw !important;
    left: 50% !important;
  }
}

/* Add subtle border */
.resume-popup iframe {
  border: none;
  background: white;
}

.dark .resume-popup {
  border-color: rgba(255, 255, 255, 0.1);
}

/* Dark mode adjustments - improve PDF visibility in dark mode */
.dark .resume-popup iframe {
  filter: invert(0.9) hue-rotate(180deg);
  background: white;
}


/* Add subtle shadow effect on hover */
.resume-popup:hover {
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.25), 0 0 10px rgba(74, 159, 255, 0.2);
  transition: box-shadow 0.3s ease;
}

/* In case the PDF doesn't load, show a nice placeholder */
.pdf-preview::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  z-index: -1;
}

iframe.pdf-preview {
  transform: scale(0.95);
  transform-origin: top center;
}

/* Mobile optimization */
@media (max-width: 640px) {
  .resume-popup {
    width: 300px !important;
    left: 50% !important;
  }
  
  .resume-popup .h-\[400px\] {
    height: 350px !important;
  }
}

/* Scrollbar styling for CV preview */
.cv-preview::-webkit-scrollbar {
  width: 6px;
}

.cv-preview::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.cv-preview::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.cv-preview::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Dark mode adjustments */
.dark .cv-preview::-webkit-scrollbar-track {
  background: #333;
}

.dark .cv-preview::-webkit-scrollbar-thumb {
  background: #666;
}

.dark .cv-preview::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.resume-popup button {
  border: 1px solid transparent;
}

.resume-popup button:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
}



/* Make sure email text wraps properly and doesn't overflow */
.break-words {
  word-break: break-word;
  overflow-wrap: break-word;
  -ms-word-break: break-all;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 100%;
}

/* Add hover effect for email link */
.text-gradient {
  transition: all 0.3s ease;
}

.text-gradient:hover {
  filter: brightness(1.1);
  text-shadow: 0 0 10px rgba(74, 159, 255, 0.2);
  transform: translateY(-2px);
}

/* Ensure container has proper padding on small screens */
@media (max-width: 640px) {
  .section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

/* Custom animation for email */
@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Apply animation to email on hover */
a.text-gradient:hover {
  background-size: 200% 200%;
  animation: gradientShift 3s ease infinite;
}


/* Effects for technoliges.js page */

/* Tech icon container styling */

.tech-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding: 1rem;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
}

/* Standardized image sizes */
.tech-img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  transition: all 0.3s ease;
  filter: grayscale(20%);
}

/* Enhanced pulsing animations - more pronounced */
.tech-icon:nth-child(3n+1) .tech-img {
  animation: enhanced-pulse 4s ease-in-out infinite;
}

.tech-icon:nth-child(3n+2) .tech-img {
  animation: enhanced-pulse 5s ease-in-out infinite 0.5s;
}

.tech-icon:nth-child(3n) .tech-img {
  animation: enhanced-pulse 4.5s ease-in-out infinite 1s;
}

@keyframes enhanced-pulse {
  0%, 100% {
    transform: scale(0.9);
    filter: grayscale(30%);
  }
  50% {
    transform: scale(1.1);
    filter: grayscale(0%);
  }
}

/* Enhanced hover effects with 3D transformation and glow */
.tech-icon:hover {
  transform: perspective(500px) rotateX(10deg) rotateY(-10deg) translateY(-8px) translateZ(20px);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.2),
    0 0 15px rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.15);
  transition: all 0.3s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}

.tech-icon:hover .tech-img {
  transform: scale(1.25) translateZ(10px);
  filter: grayscale(0%) drop-shadow(0 0 8px rgba(255, 255, 255, 0.5));
  animation-play-state: paused; /* Pause animation on hover */
}

/* Advanced glow effect on hover with animated gradient */
.tech-icon::after {
  content: '';
  position: absolute;
  inset: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    from 0deg at 50% 50%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 25%, 
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0;
  transition: opacity 0.5s ease;
  animation: spin 8s linear infinite;
  transform-origin: center;
  pointer-events: none;
  mix-blend-mode: overlay;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.tech-icon:hover::after {
  opacity: 1;
}

/* Enhanced dark mode adjustments */
.dark .tech-icon {
  background-color: rgba(255, 255, 255, 0.03);
}

.dark .tech-icon:hover {
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: 
    0 15px 35px rgba(0, 0, 0, 0.4),
    0 0 15px rgba(100, 100, 255, 0.2);
  border: 1px solid rgba(100, 149, 237, 0.15);
}

/* Add tooltip on hover */
.tech-icon {
  position: relative;
}

.tech-icon::before {
  content: attr(title);
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.tech-icon:hover::before {
  opacity: 1;
}

/* Optional: Add a subtle background pulse as well */
.tech-icon {
  animation: bg-pulse 8s ease-in-out infinite;
}

@keyframes bg-pulse {
  0%, 100% {
    background-color: rgba(255, 255, 255, 0.05);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

/* Dark mode specific background pulse */
.dark .tech-icon {
  animation: dark-bg-pulse 8s ease-in-out infinite;
}

@keyframes dark-bg-pulse {
  0%, 100% {
    background-color: rgba(255, 255, 255, 0.03);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.07);
  }
}